<template>
  <div class="bg bg22">
    <div class="class--con">
      <class-but @forward="forward" />
      <div class="video">
        <video controls="controls"
               :src="videoUrl"></video>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";


export default {
  name: "theImportanceOfAestheticEducation",
  components: {ClassBut},
  data() {
    return {
      videoUrl:null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let res = await service.get(`/dwart/an_teaching/hbk/v1/getAdvVideo?classId=${this.$route.query.classId}`)
      if ( this.$route.query.subjectName === '书法' ) {
        this.videoUrl = res.videoShufaPart2Url
      }else {
        this.videoUrl = res.videoNomalPart2Url
      }
    },
    forward() {
      this.$router.push({
        name: 'groupPhoto',
        query: this.$route.query,
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import "../ConferenceItem/reportClass.less";

.video {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 1349px;
    height: 778px;
    border: 20px solid #ffe16c;
    border-radius: 20px;
  }
}
</style>
